import { Utils } from "../../Utils"
/** This code is autogenerated - do not edit. Edit ./assets/layers/usersettings/usersettings.json instead */
export class ThemeMetaTagging {
    public static readonly themeName = "usersettings"

    public metaTaggging_for_usersettings(feat: { properties: Record<string, string> }) {
        Utils.AddLazyProperty(feat.properties, "_mastodon_candidate_md", () =>
            feat.properties._description
                .match(/\[[^\]]*\]\((.*(mastodon|en.osm.town).*)\).*/)
                ?.at(1)
        )
        Utils.AddLazyProperty(
            feat.properties,
            "_d",
            () => feat.properties._description?.replace(/&lt;/g, "<")?.replace(/&gt;/g, ">") ?? ""
        )
        Utils.AddLazyProperty(feat.properties, "_mastodon_candidate_a", () =>
            ((feat) => {
                const e = document.createElement("div")
                e.innerHTML = feat.properties._d
                return Array.from(e.getElementsByTagName("a")).filter(
                    (a) => a.href.match(/mastodon|en.osm.town/) !== null
                )[0]?.href
            })(feat)
        )
        Utils.AddLazyProperty(feat.properties, "_mastodon_link", () =>
            ((feat) => {
                const e = document.createElement("div")
                e.innerHTML = feat.properties._d
                return Array.from(e.getElementsByTagName("a")).filter(
                    (a) => a.getAttribute("rel")?.indexOf("me") >= 0
                )[0]?.href
            })(feat)
        )
        Utils.AddLazyProperty(
            feat.properties,
            "_mastodon_candidate",
            () => feat.properties._mastodon_candidate_md ?? feat.properties._mastodon_candidate_a
        )
        feat.properties["__current_backgroun"] = "initial_value"
    }
}
